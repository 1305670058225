import { graphql } from 'gatsby';
import React, { Component } from 'react';

import { exists, window } from 'browser-monads';
import get from 'lodash/get';
import find from 'lodash/find';
import styled from 'styled-components';

import { LANGUAGE } from '../constants/language';
import Layout from '../layout/Layout';
import HomePageIntroduction from './HomePageIntroduction';
// import InstagramFeed from './InstagramFeed';
import HomepageAwards from './HomepageAwards';
import HomepageHero from './HomepageHero';
import HomepageExperience from './HomepageExperience';
import HomepageLandingpage from './HomepageLandingpage';
import HomepageLoading from './HomepageLoading';

interface Props {
  data: any;
  location: any;
}

interface State {
  showBooking: boolean;
}

const BookingButton = styled.button`
  display: none;
  position: fixed;
  left: calc(50% - 62px);
  bottom: 59px;
  height: 34px;
  min-height: 34px;
  width: 124px;
  z-index: 4;
  background-color: #b59660 !important;
  border-color: #b59660 !important;
  border-style: none;
  color: #ffffff !important;
  cursor: pointer;
  outline: none !important;

  @media (max-width: 992px) {
    display: block;
  }
`;

class Homepage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showBooking: false,
    };
  }

  componentDidMount() {
    // this.setState({ showBooking: localStorage.getItem("SHOWBOOKING") != null ? true : false });
    this.setState({ showBooking: true });
  }

  handleUserMenu = ({ key, domEvent }): void => {
    if (exists(window)) {
      const loc = window.location.toString();
      const params = loc.split('?')[1];
      // console.log("LOC" + loc, window.location);
      let bookingRelPath = 'booking';

      if (localStorage.getItem('SHOWBOOKING') === 'dev') {
        bookingRelPath = 'dev-booking';
      }
      // } else if (localStorage.getItem("SHOWBOOKING") === "demo") {
      //   bookingRelPath = 'booking';
      // } else if (localStorage.getItem("SHOWBOOKING") === "prod") {
      //   bookingRelPath = 'booking';
      // } else if (localStorage.getItem("SHOWBOOKING") === "local") {
      //   bookingRelPath = 'booking';
      // }

      const lang = get(this.props.data, 'page.lang', 'en-us');
      window.location.href =
        window.location.origin +
        `/${LANGUAGE[lang]}/${bookingRelPath}/?page=${key}`;
    }
  };

  render() {
    const { data, location } = this.props;
    const prevPath = get(location, 'state.prevPath', '');
    const body = get(data, 'page.data.body');
    const landingPage = find(body, {
      __typename: 'PrismicHomepageBodyLandingPage',
    });
    const landingPageImg = get(landingPage, 'primary.image.localFile');

    const pageHero = find(body, {
      __typename: 'PrismicHomepageBodyHero',
    });

    return (
      <Layout data={data} isHomePage location={this.props.location}>
        {prevPath === '' && <HomepageLoading data={get(data, 'loadingLogo')} />}

        {this.state.showBooking ? (
          <BookingButton
            type="primary"
            size="large"
            id="homepage_btn_bookNow"
            onClick={(e) => {
              this.handleUserMenu({ key: 'search', domEvent: e });
            }}
          >
            {get(pageHero, 'primary.book_now_button', 'BOOK NOW')}
          </BookingButton>
        ) : (
          <></>
        )}

        {Array.isArray(body) &&
          body.map((slice) => {
            switch (slice.__typename) {
              case 'PrismicHomepageBodyIntroduction':
                return (
                  <HomePageIntroduction
                    key={slice.id + slice.__typename}
                    data={slice}
                    lang={get(data, 'page.lang', 'en-us')}
                  />
                );
              case 'PrismicHomepageBodyAwards':
                return (
                  <HomepageAwards
                    key={slice.id + slice.__typename}
                    data={slice}
                    lang={get(data, 'page.lang', 'en-us')}
                  />
                );
              case 'PrismicHomepageBodyHero':
                return (
                  <HomepageHero
                    key={slice.id + slice.__typename}
                    data={slice}
                  />
                );
              case 'PrismicHomepageBodyInstagramFeed':
                // return (
                //   <InstagramFeed
                //     key={slice.id + slice.__typename}
                //     data={slice}
                //   />
                // );
                return <div key={slice.__typename} />;
              case 'PrismicHomepageBodyExperience':
                return (
                  <HomepageExperience
                    key={slice.id + slice.__typename}
                    lang={get(data, 'page.lang', 'en-us')}
                    data={slice}
                  />
                );
              default:
                return null;
            }
          })}
        {landingPageImg && <HomepageLandingpage data={data} />}
      </Layout>
    );
  }
}

export default Homepage;

// Query metadata for the homepage
export const query = graphql`
  query IndexQuery($slug: String!, $language: String!) {
    page: prismicHomepage(id: { eq: $slug }) {
      ...Homepage
    }
    loadingLogo: prismicSiteLogo(lang: { eq: $language }) {
      ...Loading
    }
  }
`;
