import React, { Component } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Modal, Button } from 'antd';
import { Image } from '../common/components';

const localStorage = require('localStorage');

interface Props {
  data: any;
  getContainer?: (instance: React.ReactInstance) => HTMLElement;
}

const LandingPageImage = styled(Image)`
  width: 100%;
  max-height: 600px;

  * {
    object-fit: contain !important;
  }
`;
const Container = styled.div``;
const ModalStyled = styled(Modal)`
  .ant-modal-content {
    background: transparent;
    padding: 0;
  }
  .ant-modal-body {
    /* padding-top: 3rem; */
    padding: 0;
  }
  i {
    /* box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3); */
    filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.3));
  }

  /* .ant-modal-close {
    top: 5px;
    right: 5px;
  } */
  .ant-modal-close-x {
    font-size: 25px;
    ${breakpoint('lg')`
     font-size: 30px;
  `};
  }
  width: 100% !important;
  max-width: 800px;
  max-height: 600px;
  ${breakpoint('lg')`
    width: 80%!important;
  `};
`;

class Homepage extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      showLanding: false,
      landingPageData: null,
      lang: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const body = get(data, 'page.data.body');
    const lang = get(data, 'page.lang', 'en-us');
    const landingPageData = find(body, {
      __typename: 'PrismicHomepageBodyLandingPage',
    });

    if (landingPageData) {
      const landingPageImg = get(landingPageData, 'primary.image');
      const id = get(landingPageImg, 'localFile.id');
      const key = `landingPage_${lang}`;
      const landingPageIdFromLocal = localStorage.getItem(key);
      if (id !== landingPageIdFromLocal) this.setState({ showLanding: true });
    }
    this.setState({ landingPageData, lang });
  }

  onClose = () => {
    const { landingPageData, lang } = this.state;
    const landingPageImg = get(landingPageData, 'primary.image');
    const id = get(landingPageImg, 'localFile.id');
    const key = `landingPage_${lang}`;
    localStorage.setItem(key, id);
    this.setState({ showLanding: false });
  };

  render() {
    const { data, getContainer } = this.props;
    const { showLanding, landingPageData } = this.state;

    const landingPageImg = get(landingPageData, 'primary.image');

    return (
      <Container>
        <ModalStyled
          // title="Vertically centered modal dialog"
          // width={'80%'}
          // style={{ width: 800, background: 'transparent' }}
          centered
          closable
          bodyStyle={{ background: 'transparent' }}
          visible={showLanding}
          onOk={this.onClose}
          onCancel={this.onClose}
          footer={null}
          getContainer={getContainer}
          // onCancel={() => this.setModal2Visible(false)}
        >
          {landingPageImg && <LandingPageImage data={landingPageImg} />}
        </ModalStyled>
      </Container>
    );
  }
}

export default Homepage;
