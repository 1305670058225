import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import get from 'lodash/get';
import Texty from 'rc-texty';
import Img from 'gatsby-image';
import TweenOne from 'rc-tween-one';
import { Title, Image } from '../common/components';

import 'rc-texty/assets/index.css';

interface Props {
  data: string;
  getContainer?: (instance: React.ReactInstance) => HTMLElement;
}

const TweenOneWithStyled = styled(TweenOne)`
  opacity: 1;
  background: white;
  z-index: 20;
  position: fixed;
  width: '100%';
  margin-top: -65px;
  padding-bottom: 65px;

  ${breakpoint('lg')`
   ${css`
     margin-top: -30px;
     padding: 30px 0;
   `}
  `};
`;

const GatsbyImage = styled(Img)`
  margin-bottom: 2rem;
  width: 100px;
  ${breakpoint('lg')`
    width:180px;
  `}
`;

const Container = styled.div`
  /* position: fixed; */
  width: 100%;
  height: 100%;
  z-index: 20;
`;
const LoadingContainer = styled.div`
  background: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const LogoImg = styled(Image)`
  margin-bottom: 2rem;
  width: 50px;
  min-height: 70px;
  ${breakpoint('lg')`
    width:80px;
    min-height: 100px;
  `}
`;

class HomepageLoading extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      mode: 'smooth',
      type: 'alpha',
      momentLoading: null,
      reverseLoading: false,
      showLoading: true,
    };
  }

  onStart = () => {
    this.setState({
      reverseLoading: false,
      momentLoading: null,
    });
  };

  onReverse = () => {
    this.setState({
      reverseLoading: true,
      momentLoading: null,
    });
  };

  componentDidMount() {
    this.onStart();
  }

  onComplete = (e) => {
    this.setState({ showLoading: false });
  };

  render() {
    const { data } = this.props;
    const { showLoading, reverseLoading, momentLoading } = this.state;
    const loadingLogo = get(data, 'data.loadingLogo');

    return (
      <div>
        {showLoading && (
          <TweenOneWithStyled
            style={{
              opacity: 1,
              background: 'white',
              zIndex: 20,
              position: 'fixed',
              width: '100%',
            }}
            reverse={reverseLoading}
            moment={momentLoading}
            animation={[
              {
                opacity: 1,
                duration: 1000,
                background: '#111111',
              },
              {
                opacity: 0,
                duration: 1000,
                delay: 1500,
                scale: 1.5,
                onComplete: this.onComplete,
              },
            ]}
          >
            <Container>
              <LoadingContainer>
                {loadingLogo && (
                  <TweenOne
                    style={{ opacity: 0 }}
                    animation={{ opacity: 1, delay: 1000 }}
                  >
                    <LogoImg data={loadingLogo} />
                  </TweenOne>
                )}

                <Title>
                  <Texty
                    type={this.state.type}
                    mode={this.state.mode}
                    delay={1000}
                  >
                    {this.state.show && 'Nikanti Golf Club'}
                  </Texty>
                </Title>
              </LoadingContainer>
            </Container>
          </TweenOneWithStyled>
        )}
      </div>
    );
  }
}

export default HomepageLoading;
