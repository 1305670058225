import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { get, find } from 'lodash';
import { Row, Col } from 'antd';
import {
  Title,
  Text,
  Spacer,
  Link,
  Card,
  FlowerPattern,
} from '../common/components';
import { theme } from '../common/theme';
import { getInternalUrl } from '../utils';
import flowerGold1 from '../assets/flower-3-gold.svg';
import flowerGold2 from '../assets/flower-2-gold.svg';
import { ItemsSlider } from '../common/components';

const Container = styled.div`
  position: relative;
  min-height: 140px;
  margin-top: 50px;
  margin-bottom: 50px;
  bottom: 0;
`;

const AwardTitle = styled(Title)`
  font-weight: normal !important;
  margin-bottom: 0 !important;
  text-align: center;
`;

const CardTitle = styled(Title)``;

const AwardSpacer = styled(Spacer)`
  margin: 30px auto !important;
`;

const AwardLinkText = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`;

const AwardCard = styled(Card)`
  margin: 0 10px;
  ${breakpoint('md')`
    margin-left: 10px;
    margin-right: 10px;
    height: 250px;
  `}
`;

const AwardCardContent = styled(Card.Content)`
  padding: 20px;
  height: 130px;
  ${breakpoint('md')`
    padding: 30px;
  `}
  flex-direction:row!important;
`;

// Own awards item component
const AwardItem = ({ title, image, link, linkText, lang }) => (
  <AwardCard type="flex">
    {image && (
      <Card.CoverImageContainer xs={24} sm={11}>
        <Card.CoverImage
          fluid={get(image, 'localFile.childImageSharp.fluid')}
          alt={get(image, 'alt')}
          imgStyle={{ 'object-position': 'top center' }}
          showAnimation
        />
      </Card.CoverImageContainer>
    )}
    <Card.ContentContainer xs={24} sm={image ? 13 : 24}>
      <AwardCardContent type="flex" justify="space-between">
        {title && (
          <Col>
            {/* <Card.Title color={theme.color.background.dark}>{title}</Card.Title> */}
            <CardTitle level={3} color={theme.color.background.dark}>
              {title}
            </CardTitle>
          </Col>
        )}
        {link && linkText && (
          <Col style={{ marginTop: title ? 20 : 0 }}>
            <Link to={getInternalUrl(link, lang)} target="">
              <Card.LinkText color={theme.color.background.dark}>
                {linkText}
              </Card.LinkText>
            </Link>
          </Col>
        )}
      </AwardCardContent>
    </Card.ContentContainer>
  </AwardCard>
);

interface Props {
  data: any;
  lang: string;
  key: string;
}

const CreateAwardItems = (items: any, lang: string) => {
  const awardsItem = items.map((item, i) => {
    const document = get(item, 'article.document[0]');
    if (!document) return null;

    const id = get(document, 'id', i);
    const title = get(document, 'data.title', '');
    const url = get(document, 'data.url', '');
    const linkText = get(document, 'data.read_more_text');
    const featureImage = get(document, 'data.preview_image');

    return (
      <AwardItem
        key={id}
        title={title}
        image={featureImage}
        link={url}
        linkText={linkText}
        lang={lang}
      />
    );
  });
  return awardsItem;
};

class HomepageAwards extends React.Component<Props, any> {
  render() {
    const { data = null } = this.props;
    if (!data) return null;

    const { items = [], primary = null } = data;

    const awardsBody = get(primary, 'awards.document[0].data.body', null);

    const awardsArticlesSliceData = find(awardsBody, {
      __typename: 'PrismicAwardsBodyArticles',
    });
    const awardsItems = get(awardsArticlesSliceData, 'items', []);
    const title = get(primary, 'awardTitle', '');
    const linkText = get(primary, 'awardLinkText', '');
    const link = get(primary, 'awards.document[0].data.url', '');

    return (
      <Container>
        <FlowerPattern
          src={flowerGold1}
          height={'100px'}
          width={'auto'}
          right={'96%'}
          bottom={'0'}
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerGold2}
          height={'140px'}
          width={'auto'}
          right={'10px'}
          bottom={'80%'}
          opacity={0.6}
        />

        {title && (
          <Row type="flex" justify="center" align="top">
            <Col span={24}>
              <AwardTitle level={2} color="#F4F0E5">
                {title}
              </AwardTitle>
            </Col>
            <Col span={24}>
              <AwardSpacer color="#787760" />
            </Col>
          </Row>
        )}
        {awardsItems.length > 0 && (
          <Row>
            <Col span={24}>
              <ItemsSlider
                sliderItems={CreateAwardItems(
                  awardsItems,
                  this.props.lang || ''
                )}
              />
            </Col>
          </Row>
        )}
        {linkText && link && (
          <Row type="flex" justify="center" align="top">
            <Col>
              <Link to={getInternalUrl(link, this.props.lang)}>
                <AwardLinkText>{linkText}</AwardLinkText>
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default HomepageAwards;
