import React, { Component, useRef } from 'react';
import get from 'lodash/get';
import Media from 'react-media';
import styled, { withTheme, css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import VideoPlayer from 'react-background-video-player';
import { Row, Typography } from 'antd';
import { ImageSlider, Image, Text, Title } from '../common/components';
import { ITheme } from '../common/theme';
import { window, exists } from 'browser-monads';
import { Animated } from 'react-animated-css';

interface Props {
  data: any;
  theme: ITheme;
}

const Container = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
  padding-top: 70px;

  ${breakpoint('lg')`
   ${css`
     height: ${(props) =>
       props.containerWidth / props.containerHeight < 1.3 &&
       props.containerWidth < props.containerHeight
         ? 60
         : 120}vh;
     min-height: ${(props) =>
       props.containerWidth / props.containerHeight < 1.3 &&
       props.containerWidth < props.containerHeight
         ? 60
         : 120}vh;
     padding-top: 0;
     flex-direction: row;
   `}
  `}
`;
const VideoPlayerWithStyled = styled(VideoPlayer)`
  video {
    min-width: 100%;
    height: ${(props) => props.containerHeight}px;
    width: ${(props) => props.containerWidth}px;
    ${breakpoint('lg')`
      height: ${(props) =>
        props.containerWidth / props.containerHeight < 1.3 &&
        props.containerWidth < props.containerHeight
          ? '60vh'
          : props.containerHeight + 'px'};
      `}
    object-fit: cover;
  }
`;

const HighlightText = styled(Title)`
  text-align: center;
  color: ${(props) => props.theme.color.primary.main} !important;
  font-weight: 400;
  margin-top: 0.5em !important;
  ${breakpoint('lg')`
    margin-bottom: 2em !important;
    margin-top: 1.2em !important;
  `}
`;

const CaptionText = styled(Title)`
  line-height: 1.5em !important;
  letter-spacing: 0.5px !important;
  ${breakpoint('lg')`
    font-size: 18px !important;
  `}
  text-align: center;
  color: ${(props) => props.theme.color.text.main} !important;
  p {
    margin-bottom: 0.5em !important;
  }
`;

const LinkText = styled(Text)`
  text-align: center;
  font-weight: bold;
  transition: color 0.3s;
  color: ${(props) => props.theme.color.text.main} !important;
`;

const ReadMoreLink = styled.div`
  color: ${(props) => props.theme.color.text.dark};
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  :hover {
    * {
      color: ${(props) => props.theme.color.primary.main}!important;
    }
  }
`;

const ContentTextContainer = styled(Row)`
  flex: 1;
  z-index: 2;
  align-items: center;
  align-items: center;
  ${breakpoint('lg')`
    margin: 0;
    position: absolute;
    width: 50%;
    left: 0;
  `}
`;

const ContentTextInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem !important;

  ${breakpoint('lg')`
    font-size: 18px;
    margin-bottom: 4rem !important;
  `}
`;

const LogoImage = styled(Image)`
  width: 200px;
`;

const MaskBackgroundContainer = styled.div`
  display: none;
  height: 100vh;
  width: 100%;
  z-index: 1;
  padding-top: 0;
  flex-direction: row;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  background-image: url(${(props) =>
    props.coverImage.localFile.childImageSharp.fluid.src});
  ${breakpoint('lg')`
   ${css`
     height: ${(props) =>
       props.containerWidth / props.containerHeight < 1.3 &&
       props.containerWidth < props.containerHeight
         ? 60
         : 120}vh;
     display: block;
   `}
  `}
`;
const ImageBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${(props) =>
    props.image.localFile.childImageSharp.fluid.src});
`;

const AnimatedMaskBackground = styled(Animated)`
  width: 100%;
  z-index: 1;
  height: 100vh;
  ${breakpoint('lg')`
   ${css`
     height: ${(props) =>
       props.containerWidth / props.containerHeight < 1.3 &&
       props.containerWidth < props.containerHeight
         ? 60
         : 120}vh;
   `}
  `}
`;
const MaskBackground = (props) => (
  <AnimatedMaskBackground
    animationIn={'fadeIn'}
    animationOut={'fadeOut'}
    animationInDuration={2000}
    animationOutDuration={2000}
    isVisible={props.showCoverImage}
    animateOnMount={false}
    {...props}
  >
    <MaskBackgroundContainer {...props} />
  </AnimatedMaskBackground>
);

class HomePageHero extends Component<Props, any> {
  player: any;
  constructor(props) {
    super(props);
    this.state = {
      showCoverImage: true,
      isVideoReady: false,
      isLoading: true,
    };
  }

  renderImage = ({ image }, idx) => (
    <Image key={idx} data={image} index={idx} />
  );

  readMoreAction = () => {
    if (exists(window)) window.scroll({ top: 0, behavior: 'smooth' });
    this.setState({ showCoverImage: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showCoverImage !== this.state.showCoverImage) {
      this.player.setCurrentTime(0);
      this.player.play();
    }
  }
  render() {
    const { data, theme } = this.props;
    const logo = get(data, 'primary.siteLogo.document[0].data.logo', null);
    const coverImage = get(data, 'primary.coverImage', null);
    const images = get(data, 'items', []);
    const coverVideo = get(data, 'primary.video', null);
    const coverVideoSmall = get(data, 'primary.videoSmall', null);

    const { showCoverImage } = this.state;

    return (
      <Container
        containerWidth={exists(window) && window.innerWidth}
        containerHeight={exists(window) && window.innerHeight}
      >
        <ContentTextContainer type="flex" justify="center">
          <Animated
            animationIn={'fadeIn'}
            animationOut={'fadeOut'}
            animationInDuration={2000}
            animationOutDuration={2000}
            animationOutDelay={500}
            isVisible={showCoverImage}
            animateOnMount={false}
          >
            <ContentTextInner>
              <LogoContainer>{logo && <LogoImage data={logo} />}</LogoContainer>
              <Media
                query={`(min-width: ${theme.breakpoints.lg}px)`}
                defaultMatches={true}
                render={() => (
                  <div>
                    <CaptionText level={1} size={3}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: get(data, 'primary.caption.html', ''),
                        }}
                      />
                    </CaptionText>
                    <HighlightText level={2} size={1}>
                      {get(data, 'primary.highlight', '')}
                    </HighlightText>
                    <ReadMoreLink
                      onClick={coverVideo.url && this.readMoreAction}
                    >
                      <LinkText small>
                        {get(data, 'primary.experienceLinkText')}
                      </LinkText>
                    </ReadMoreLink>
                  </div>
                )}
              />
              <Media
                query={`(max-width: ${theme.breakpoints.lg - 1}px)`}
                defaultMatches={true}
                render={() => (
                  <div>
                    <CaptionText level={1} size={3}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: get(data, 'primary.caption.html', ''),
                        }}
                      />
                    </CaptionText>
                    <HighlightText level={2} size={1}>
                      {get(data, 'primary.highlight', '')}
                    </HighlightText>
                  </div>
                )}
              />
            </ContentTextInner>
          </Animated>
        </ContentTextContainer>

        <ImageSlider
          images={images}
          isHomePage={true}
          renderItem={this.renderImage}
        />
        {coverVideo && (
          <Media
            query={`(min-width: ${theme.breakpoints.lg}px)`}
            defaultMatches={true}
            render={() => (
              <div>
                {showCoverImage && coverVideoSmall && coverVideoSmall.url && (
                  <VideoPlayerWithStyled
                    ref={(p) => (this.player = p)}
                    containerHeight={
                      exists(window) &&
                      (showCoverImage
                        ? window.innerHeight * 1.19
                        : window.innerHeight)
                    }
                    containerWidth={exists(window) && window.innerWidth}
                    disableBackgroundCover={true}
                    src={coverVideoSmall.url}
                    autoPlay={showCoverImage}
                    volume={0}
                    loop={false} //showCoverImage
                    onEnd={() => {
                      this.setState({ showCoverImage: true });
                    }}
                    onClick={() => this.setState({ showCoverImage: true })}
                    //onReady={a => console.log('>>> onReady', a)}
                    preload={'auto'}
                  />
                )}
                {!showCoverImage && coverVideo.url && (
                  <VideoPlayerWithStyled
                    ref={(p) => (this.player = p)}
                    containerHeight={
                      exists(window) &&
                      (showCoverImage
                        ? window.innerHeight * 1.19
                        : window.innerHeight)
                    }
                    containerWidth={exists(window) && window.innerWidth}
                    disableBackgroundCover={true}
                    src={coverVideo.url}
                    //poster={coverImage && coverImage.url}
                    autoPlay={showCoverImage}
                    volume={0}
                    loop={showCoverImage}
                    onEnd={() => {
                      this.setState({ showCoverImage: true });
                    }}
                    onClick={() => this.setState({ showCoverImage: true })}
                    //onReady={a => console.log('>>> onReady', a)}
                    preload={'auto'}
                  />
                )}
                {showCoverImage &&
                  !coverVideoSmall.url &&
                  images.length > 0 && <ImageBg image={images[0].image} />}
              </div>
            )}
          />
        )}
        <Media
          query={`(min-width: ${theme.breakpoints.lg}px)`}
          defaultMatches={true}
          render={() => (
            <MaskBackground
              coverImage={coverImage}
              showCoverImage={showCoverImage}
              containerWidth={exists(window) && window.innerWidth}
              containerHeight={exists(window) && window.innerHeight}
            />
          )}
        />
      </Container>
    );
  }
}

export default withTheme(HomePageHero);
