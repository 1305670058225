import React, { Component } from 'react';
import get from 'lodash/get';
import Img from 'gatsby-image';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'antd';
import {
  FlowerPattern,
  Text,
  Paragraph,
  Title,
  Link,
} from '../common/components';
import { fadeIn, fadeOut, fadeInLeft, fadeOutLeft } from 'react-animations';
import { getInternalUrl } from '../utils';

import { Animated } from 'react-animated-css';
import flowerThickDarkGreen from '../assets/flower-thick-dark-green.svg';
import flowerMediumDarkGreen from '../assets/flower-medium-dark-green.svg';
import flowerThickLightGold from '../assets/flower-thick-light-gold.svg';
import flowerMediumLightGold from '../assets/flower-medium-light-gold.svg';
import flowerSpreadDark from '../assets/flower-4-gray.svg';

import { theme } from '../common/theme';

interface Props {
  data: any;
  lang: string;
}

const AnimatedWithStyled = styled(Animated)`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Container = styled(Row)`
  margin: 40px 20px;
  position: relative;
`;

const MidRow = styled(Row)``;

const CardCol = styled(Col)`
  align-content: center;
  margin-left: 9px !important;
  margin-right: 9px !important;
`;

const SeeRatesCol = styled(Col)`
  align-content: center;
  text-align: center;
  margin-top: 30px;
`;

const ExperienceImage = styled(Img)`
  width: 100%;
  height: 100%;
`;

const MobileExperienceImageContainer = styled.div`
  height: 400px;
  ${breakpoint('xs')`
    height: 30vh;
  `}
  ${breakpoint('md')`
    height: 40vh;
  `}
`;

const CardTitleContainer = styled.div`
  position: absolute;
  bottom: 8px;
  max-width: 250px;
  margin-left: 2em;
`;

const CardTitle = styled(Title)`
  text-align: left;
  font-weight: bold;
`;

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: ${props => (props.height ? props.height : '450px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`;

const ExperienceDescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : theme.color.primary.main};
`;

const DescriptionTitle = styled(Title)`
  text-align: left;
  font-weight: bold;
`;

const InsideDescriptionTitle = styled(Text)`
  text-align: left;
  font-weight: bold;
  padding-bottom: 2.5em !important;
`;

const DescriptionText = styled(Paragraph)`
  color: ${props => props.theme.color.text.gray} !important;
  font-size: 16px;
  text-align: left;
  ${props => props.extraPadding && 'padding-top: 1em !important;'}
`;

const DescriptionReadMore = styled(Text)`
  color: ${props => props.theme.color.text.gray} !important;
  font-size: 16px;
  text-align: left;
  font-weight: bold;
`;

const DescriptionRow = styled(Row)`
  width: 100%;
  padding: 14px 20px;
`;

const DescriptionCol = styled(Col)``;

const SeeRatesText = styled(Text)`
  color: ${props => props.theme.color.text.main} !important;
  font-weight: bold;
`;

const NonHover = styled.span`
  width: 100%;
  height: 100%;
`;

const Hover = styled.span`
  width: 100%;
  height: 100%;
  opacity: 0.95;
`;

class DesktopExperienceCard extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }
  hoverOn = () => {
    this.setState({ hover: true });
  };
  hoverOff = () => {
    this.setState({ hover: false });
  };
  render() {
    const { data, animationIn, animationOut } = this.props;
    const { hover } = this.state;
    const imgSrc = get(data, 'image', null);
    return (
      <CardContainer
        height={data.height}
        marginTop={data.marginTop}
        className="hover-parent"
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
      >
        <AnimatedWithStyled
          animationIn={'fadeIn'}
          animationOut={'fadeOut'}
          animationInDuration={1000}
          animationOutDuration={1000}
          animationOutDelay={500}
          isVisible={true}
        >
          <NonHover className="non-hovering">
            {imgSrc && <ExperienceImage fluid={imgSrc} alt={data.alt} />}
            <CardTitleContainer>
              <CardTitle level={2}>{get(data, 'title')}</CardTitle>
            </CardTitleContainer>
          </NonHover>
        </AnimatedWithStyled>
        <AnimatedWithStyled
          animationIn={animationIn}
          animationOut={animationOut}
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={hover}
        >
          <Hover className="hovering">
            <ExperienceDescriptionContainer
              backgroundColor={data.descriptionBackgroundColor}
            >
              <FlowerPattern
                forceDisplay={true}
                src={
                  data.descriptionBackgroundColor === theme.color.primary.main
                    ? flowerMediumLightGold
                    : flowerMediumDarkGreen
                }
                height={'50%'}
                width={'auto'}
                right={'-10%'}
                bottom={'50%'}
                opacity={0.8}
              />
              <FlowerPattern
                forceDisplay={true}
                src={
                  data.descriptionBackgroundColor === theme.color.primary.main
                    ? flowerThickLightGold
                    : flowerThickDarkGreen
                }
                height={'15%'}
                width={'auto'}
                right={'20%'}
                bottom={'30%'}
                opacity={0.8}
              />
              <DescriptionRow type="flex" justify="start">
                <DescriptionCol xs={24}>
                  <InsideDescriptionTitle
                    color={theme.color.text.gray}
                    size={2}
                  >
                    {get(data, 'title')}
                  </InsideDescriptionTitle>
                </DescriptionCol>
                <DescriptionCol xs={24}>
                  <DescriptionText extraPadding={true}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: get(data, 'description'),
                      }}
                    />
                  </DescriptionText>
                </DescriptionCol>
                <DescriptionCol xs={24}>
                  <Link href={get(data, 'linkUrl')}>
                    <DescriptionReadMore>
                      {get(data, 'linkText')}
                    </DescriptionReadMore>
                  </Link>
                </DescriptionCol>
              </DescriptionRow>
            </ExperienceDescriptionContainer>
          </Hover>
        </AnimatedWithStyled>
      </CardContainer>
    );
  }
}

const MobileExperienceCard = ({ data }) => {
  const imgSrc = get(data, 'image', null);
  return (
    <CardContainer height={data.height} marginTop={data.marginTop}>
      <MobileExperienceImageContainer>
        {imgSrc && <ExperienceImage fluid={imgSrc} alt={data.alt} />}
      </MobileExperienceImageContainer>
      <ExperienceDescriptionContainer
        backgroundColor={data.descriptionBackgroundColor}
      >
        <FlowerPattern
          forceDisplay={true}
          src={
            data.descriptionBackgroundColor === theme.color.primary.main
              ? flowerMediumLightGold
              : flowerMediumDarkGreen
          }
          height={'70%'}
          width={'auto'}
          right={'-2%'}
          bottom={'40%'}
          opacity={0.8}
        />
        <FlowerPattern
          forceDisplay={true}
          src={
            data.descriptionBackgroundColor === theme.color.primary.main
              ? flowerThickLightGold
              : flowerThickDarkGreen
          }
          height={'20%'}
          width={'auto'}
          right={'5%'}
          bottom={'10%'}
          opacity={0.8}
        />
        <DescriptionRow type="flex" justify="start">
          <DescriptionCol xs={24}>
            <DescriptionTitle color={theme.color.text.gray} level={2}>
              {get(data, 'title')}
            </DescriptionTitle>
          </DescriptionCol>
          <DescriptionCol xs={24}>
            <DescriptionText>
              <span
                dangerouslySetInnerHTML={{
                  __html: get(data, 'description'),
                }}
              />
            </DescriptionText>
          </DescriptionCol>
          <DescriptionCol xs={24}>
            <Link href={get(data, 'linkUrl')}>
              <DescriptionReadMore>{get(data, 'linkText')}</DescriptionReadMore>
            </Link>
          </DescriptionCol>
        </DescriptionRow>
      </ExperienceDescriptionContainer>
    </CardContainer>
  );
};

class HomepageExperience extends Component<Props, any> {
  render() {
    const { data } = this.props;

    return (
      <Container type="flex" justify="center">
        {/* Desktop */}
        <FlowerPattern
          forceDisplay={false}
          src={flowerSpreadDark}
          height={'600px'}
          width={'800px'}
          right={'50%'}
          bottom={'0%'}
          opacity={1}
        />
        <FlowerPattern
          forceDisplay={false}
          src={flowerSpreadDark}
          height={'600px'}
          width={'800px'}
          right={'10%'}
          bottom={'200px'}
          opacity={1}
        />
        <CardCol xs={0} lg={6}>
          <DesktopExperienceCard
            data={{
              image: get(
                data,
                'primary.first_experience_image.localFile.childImageSharp.fluid'
              ),
              altImage: get(data, 'primary.first_experience_image.alt', ''),
              title: get(data, 'primary.first_experience_title', ''),
              description: get(
                data,
                'primary.first_experience_description.html',
                ''
              ),
              linkUrl: getInternalUrl(
                get(
                  data,
                  'primary.first_experience_link.document[0].data.url',
                  ''
                ) + '#golf-layout',
                this.props.lang
              ),
              linkText: get(data, 'primary.first_experience_link_text', ''),
              height: '450px',
              marginTop: '100px',
              descriptionBackgroundColor: theme.color.primary.main,
            }}
            animationIn={'fadeInLeft'}
            animationOut={'fadeOutLeft'}
          />
        </CardCol>
        <CardCol xs={0} lg={8}>
          <MidRow type="flex" justify="center">
            <CardCol xs={24}>
              <DesktopExperienceCard
                data={{
                  image: get(
                    data,
                    'primary.second_experience_image.localFile.childImageSharp.fluid'
                  ),
                  altImage: get(
                    data,
                    'primary.second_experience_image.alt',
                    ''
                  ),
                  title: get(data, 'primary.second_experience_title', ''),
                  description: get(
                    data,
                    'primary.second_experience_description.html',
                    ''
                  ),
                  linkUrl: getInternalUrl(
                    get(
                      data,
                      'primary.second_experience_link.document[0].data.url',
                      ''
                    ) + '#dining',
                    this.props.lang
                  ),
                  linkText: get(
                    data,
                    'primary.second_experience_link_text',
                    ''
                  ),
                  height: '350px',
                  marginTop: '0px',
                  descriptionBackgroundColor: theme.color.secondary.main,
                }}
                animationIn={'fadeInDown'}
                animationOut={'fadeOutUp'}
              />
            </CardCol>
            <CardCol xs={0} lg={24}>
              <DesktopExperienceCard
                data={{
                  image: get(
                    data,
                    'primary.third_experience_image.localFile.childImageSharp.fluid'
                  ),
                  altImage: get(data, 'primary.third_experience_image.alt', ''),
                  title: get(data, 'primary.third_experience_title', ''),
                  description: get(
                    data,
                    'primary.third_experience_description.html',
                    ''
                  ),
                  linkUrl: getInternalUrl(
                    get(
                      data,
                      'primary.third_experience_link.document[0].data.url',
                      ''
                    ) + '#caddie',
                    this.props.lang
                  ),
                  linkText: get(data, 'primary.third_experience_link_text', ''),
                  height: '350px',
                  marginTop: '20px',
                  descriptionBackgroundColor: theme.color.primary.main,
                }}
                animationIn={'fadeInUp'}
                animationOut={'fadeOutDown'}
              />
            </CardCol>
          </MidRow>
        </CardCol>
        <CardCol xs={0} lg={6}>
          <DesktopExperienceCard
            data={{
              image: get(
                data,
                'primary.fourth_experience_image.localFile.childImageSharp.fluid'
              ),
              altImage: get(data, 'primary.fourth_experience_image.alt', ''),
              title: get(data, 'primary.fourth_experience_title', ''),
              description: get(
                data,
                'primary.fourth_experience_description.html',
                ''
              ),
              linkUrl: getInternalUrl(
                get(
                  data,
                  'primary.fourth_experience_link.document[0].data.url',
                  ''
                ) + '#golf-cart',
                this.props.lang
              ),
              linkText: get(data, 'primary.fourth_experience_link_text', ''),
              height: '450px',
              marginTop: '170px',
              descriptionBackgroundColor: theme.color.secondary.main,
            }}
            animationIn={'fadeInRight'}
            animationOut={'fadeOutRight'}
          />
        </CardCol>
        {/* Mobile and Tablet */}
        <CardCol xs={24} lg={0}>
          <MobileExperienceCard
            data={{
              image: get(
                data,
                'primary.first_experience_image.localFile.childImageSharp.fluid'
              ),
              altImage: get(data, 'primary.first_experience_image.alt', ''),
              title: get(data, 'primary.first_experience_title', ''),
              description: get(
                data,
                'primary.first_experience_description.html',
                ''
              ),
              linkUrl: getInternalUrl(
                get(
                  data,
                  'primary.first_experience_link.document[0].data.url',
                  ''
                ) + '#golf-layout',
                this.props.lang
              ),
              linkText: get(data, 'primary.first_experience_link_text', ''),
              height: 'auto',
              marginTop: '0',
              descriptionBackgroundColor: theme.color.primary.main,
            }}
          />
        </CardCol>
        <CardCol xs={24} lg={0}>
          <MobileExperienceCard
            data={{
              image: get(
                data,
                'primary.second_experience_image.localFile.childImageSharp.fluid'
              ),
              altImage: get(data, 'primary.second_experience_image.alt', ''),
              title: get(data, 'primary.second_experience_title', ''),
              description: get(
                data,
                'primary.second_experience_description.html',
                ''
              ),
              linkUrl: getInternalUrl(
                get(
                  data,
                  'primary.second_experience_link.document[0].data.url',
                  ''
                ) + '#dining',
                this.props.lang
              ),
              linkText: get(data, 'primary.second_experience_link_text', ''),
              height: 'auto',
              marginTop: '20px',
              descriptionBackgroundColor: theme.color.secondary.main,
            }}
          />
        </CardCol>
        <CardCol xs={24} lg={0}>
          <MobileExperienceCard
            data={{
              image: get(
                data,
                'primary.third_experience_image.localFile.childImageSharp.fluid'
              ),
              altImage: get(data, 'primary.third_experience_image.alt', ''),
              title: get(data, 'primary.third_experience_title', ''),
              description: get(
                data,
                'primary.third_experience_description.html',
                ''
              ),
              linkUrl: getInternalUrl(
                get(
                  data,
                  'primary.third_experience_link.document[0].data.url',
                  ''
                ) + '#caddie',
                this.props.lang
              ),
              linkText: get(data, 'primary.third_experience_link_text', ''),
              height: 'auto',
              marginTop: '20px',
              descriptionBackgroundColor: theme.color.primary.main,
            }}
          />
        </CardCol>
        <CardCol xs={24} lg={0}>
          <MobileExperienceCard
            data={{
              image: get(
                data,
                'primary.fourth_experience_image.localFile.childImageSharp.fluid'
              ),
              altImage: get(data, 'primary.fourth_experience_image.alt', ''),
              title: get(data, 'primary.fourth_experience_title', ''),
              description: get(
                data,
                'primary.fourth_experience_description.html',
                ''
              ),
              linkUrl: getInternalUrl(
                get(
                  data,
                  'primary.fourth_experience_link.document[0].data.url',
                  ''
                ) + '#golf-cart',
                this.props.lang
              ),
              linkText: get(data, 'primary.fourth_experience_link_text', ''),
              height: 'auto',
              marginTop: '20px',
              descriptionBackgroundColor: theme.color.secondary.main,
            }}
          />
        </CardCol>
        {/* See Rates All Devices */}
        <SeeRatesCol xs={24}>
          <Link
            to={getInternalUrl(
              get(data, 'primary.see_rates_link.document[0].data.url', '') +
                '#prices',
              this.props.lang
            )}
          >
            <SeeRatesText>
              {get(data, 'primary.see_rates_link_text')}
            </SeeRatesText>
          </Link>
        </SeeRatesCol>
      </Container>
    );
  }
}

export default HomepageExperience;
