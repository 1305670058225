import React, { Component } from 'react';
import get from 'lodash/get';
import { Introduction } from '../common/components';
import { getInternalUrl } from '../utils';

interface Props {
  data: any;
  lang: string;
  key: string;
}

class HomePageIntroduction extends Component<Props, any> {
  render() {
    const { data } = this.props;
    const title = get(data, 'primary.title', '');
    const description = get(data, 'primary.description2', '');
    const link = get(data, 'primary.experience_link.document[0].data.url');
    const linkText = get(data, 'primary.experience_link_text');
    return (
      <Introduction
        title={title}
        //description={description.text}
        descriptionHtml={description.html}
        link={getInternalUrl(link, this.props.lang)}
        linkText={linkText}
      />
    );
  }
}

export default HomePageIntroduction;
